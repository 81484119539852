<template>
  <div>
    <b-card-title>{{ cardText }}</b-card-title>

    <b-row v-if="!shouldCrop">
      <b-col cols="12">
        <b-button @click="$refs.file.click()" :disabled="$cannot('product.create')" variant="primary" class="mb-2">
          <input
            style="display:none;"
            type="file"
            ref="file"
            @change="pick($event)"
            accept="image/*"
            :disabled="$cannot('product.create')"
          />
          Pick Image
        </b-button>

        <b-button
            v-if="product.gallery"
            @click="showGalleryImages = !showGalleryImages"
            :disabled="$cannot('product.create')"
            variant="info"
            class="mb-2 ml-4 text-white"
        >
          Pick Image From Gallery
        </b-button>
      </b-col>

      <b-col v-show="showGalleryImages" cols="12" class="mb-4 mt-3 row">
        <div v-for="(image, index) in product.gallery" :key="index" class="col-2">
          <b-img :src="image.small_url" width="128" class="mr-2 cursor-pointer" @click="pickFromGallery(index)" />
          <b-img :src="image.big_encoded" class="d-none" :ref="`image-${index}`" />
        </div>
      </b-col>

      <b-col v-if="thumbnails.length > 0" cols="auto">
        <b-row>
          <b-col cols="12" class="mb-2" v-for="thumbnail in thumbnails" :key="thumbnail.name">
            <div class="text-xl-left mb-1">{{thumbnail.name}}</div>

            <b-img :src="thumbnail.url" width="268" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <template v-if="shouldCrop">
      <div class="nav-wizards-container">
        <b-nav class="nav-wizards-2 mb-2">
          <b-nav-item v-for="(item, key, index) in sizes" class="col" :key="key" :link-classes="stepClass(index + 1)">
            {{ item.name }}
          </b-nav-item>
        </b-nav>
      </div>

      <div v-for="({ width, height }, key, index) in sizes" :key="key">
        <b-card v-if="currentStep === index + 1">
          <cropper
            :ref="`cropper-${index}`"
            :src="thumbnail"
            :stencil-props="{
              handlers: {},
              movable: false,
              scalable: false,
            }"
            :stencil-size="{
              width: width,
              height: height,
            }"
            image-restriction="stencil"
            @change="args => cropperChange(key, args)"
          />
        </b-card>
      </div>

      <hr />

      <b-row align-h="end">
        <b-col cols="auto">
          <b-button v-if="firstStep" @click="cancelStep" class="mr-2">
            Cancel
          </b-button>

          <b-button v-if="!firstStep" @click="previousStep" class="mr-2">
            Back
          </b-button>

          <b-button v-if="!lastStep" variant="primary" @click="nextStep" class="mr-2">
            Next
          </b-button>

          <b-button v-if="lastStep" @click="upload" :disabled="$cannot('product.create') || isLoading" variant="primary">
            Crop and Upload
            <b-spinner v-show="isLoading" class="ml-2" small label="Loading" />
          </b-button>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

export default {
  name: 'ProductImagesThumbnail',
  components: { Cropper },
  props: { product: Object },
  inject: ['$cannot'],
  watch: {
    $route(value) {
      if (value.name !== 'products.edit.images') {
        this.thumbnail = ''
      }
    },

    product() {
      this.setThumbnails(this.product)
    },
  },

  data() {
    return {
      thumbnail: '',
      blob: null,
      cropSizes: {},
      startCropping: false,
      isLoading: false,
      thumbnails: [],
      showGalleryImages: false,
    }
  },

  computed: {
    cardText() {
      return 'Upload a product thumbnail'
    },

    shouldCrop() {
      return this.startCropping
    },

    sizes() {
      return {
        thumb: { name: 'Thumbnail', width: 328, height: 328 },
        cart_thumb: { name: 'Cart', width: 124, height: 50 },
        cart_mini: { name: 'Mini Cart', width: 43, height: 43 },
        facebook_thumb: { name: 'Facebook', width: 600, height: 600 },
      }
    },

    totalSteps() {
      return Object.keys(this.sizes).length
    },

    currentStep() {
      return Number(this.$route.query.step ?? 0)
    },

    firstStep() {
      return this.currentStep <= 1
    },

    lastStep() {
      return this.currentStep === this.totalSteps
    },
  },
  methods: {
    setThumbnails(product) {
      const thumbnails = [];

      if (product.thumbnail?.url) thumbnails.push({name: 'Thumbnail', url: product.thumbnail.url})
      if (product.cart_thumb?.url) thumbnails.push({name: 'Cart', url: product.cart_thumb.url})
      if (product.cart_mini?.url) thumbnails.push({name: 'Mini Cart', url: product.cart_mini.url})
      if (product.facebook_thumb?.url) thumbnails.push({name: 'Facebook', url: product.facebook_thumb.url})

      this.thumbnails = thumbnails;
    },

    pick(event) {
      // Reference to the DOM input element
      const input = event.target
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        const reader = new FileReader()
        reader.addEventListener('load', event => {
          this.nextStep()
          this.thumbnail = event.target.result
          this.startCropping = true
          this.blob = input.files[0]
        })

        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0])
      }
    },

    pickFromGallery(index) {
      const img = this.$refs[`image-${index}`][0]
      this.thumbnail = img.src
      this.startCropping = true
      this.blob = new Blob([img.src], {type: 'image/jpeg'})
      this.nextStep()
    },

    stepClass(step) {
      if (this.currentStep > step) {
        return 'completed'
      }

      return step === this.currentStep ? 'active' : 'disabled'
    },

    cancelStep() {
      this.startCropping = false
      return this.$router.push({ name: 'products.edit.images' })
    },

    previousStep() {
      if (this.currentStep <= 1) {
        return
      }

      return this.$router.push({
        name: 'products.edit.images',
        query: { step: this.currentStep - 1 },
      })
    },

    nextStep() {
      if (this.currentStep === this.totalSteps) {
        return
      }

      return this.$router.push({
        name: 'products.edit.images',
        query: { step: this.currentStep + 1 },
      })
    },

    cropperChange(key, { coordinates }) {
      this.cropSizes[key] = coordinates
    },

    async upload() {
      this.isLoading = true;

      const product = await this.$store.dispatch('products/updateThumb', {
        thumbnail: this.blob,
        sizes: this.cropSizes,
        id: this.$route.params.id,
      })

      this.setThumbnails(product)

      this.startCropping = false

      this.$bvToast.toast(`A thumbnail was added to the product`, {
        title: 'Thumbnail updated',
        variant: 'primary',
      })

      this.isLoading = false;

      this.$router.push({ name: 'products.edit.images', params: { id: this.$route.params.id } })
    },
  },
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
