<template>
  <div>
    <h4><i :class="icon"></i> {{ title }}</h4>
    <p>{{ description }}</p>
    <b-card no-body>
      <b-list-group flush>
        <slot />
      </b-list-group>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'SettingsGroup',
  props: {
    title: String,
    icon: String,
    description: String,
  },
}
</script>
